import '../styles/success.scss'

import { Link } from 'gatsby'
import React from 'react'

import Layout from '../layouts/Layout'

const Success = () => {
  return (
    <Layout>
      <section id="success">
        <div className="content">
          <h1>Your message has been sent successfully!</h1>
          <p>
            Thank you for reaching out. We will get back to you as
            quickly as possible.
          </p>
          <Link to="/" replace>
            <h5>Return to INW home</h5>
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export default Success
